.header-2 {
  height: 601px;
  position: relative;
  background-size: cover;
  color: rgb(241, 241, 241);
}
  

.header-container {
  width: 1200px;
  position: absolute;
  left:50%;
  top:40%;
  transform: translate(-50%,-50%);

  .header-container-inner {
    width: 50%;

    h1 {
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 32px;
      line-height: 1.05;
    }
    
    p {
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 48px;
    }

    .btn-home{
      font-size: 18px;
      padding: 16px 32px;
    }
  }
}

  

