.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;


  .logo-container {
    padding-left: 15px;
    padding-top: 5px;
    // .logo {
    //  padding: 30px; 
    // }
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;

    .option {
      padding: 10px 15px;
      cursor: pointer;
    }

    .option:hover {
      background-color: #e2e5e7;
    }
  }
  
  .search {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .inner-search {
      position: relative;
      visibility: hidden; // toggle to render
      padding-left: 5px;
      
      .srch{
        color:rgb(0, 0, 0);
        background-color: rgba(189, 189, 189, 0.384);
        border-radius: 12px ;
        border:none ;
        margin-left: 35px;
        margin-top: 8px;
        width: 350px;
        padding: 10px 30px;
      }

      .srch:focus {
        outline: none;
        // box-shadow: 0 0 0 0.8rem rgba(253, 242, 233, 0.5);
      }
      
    }
    
    .inner-search:before {
      content: "";
      position: absolute;
      left: 50px;
      top: 0;
      bottom: 6px;
      width: 17px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill-rule='evenodd'%3E%3Cpath d='M16.036 18.455l2.404-2.405 5.586 5.587-2.404 2.404zM8.5 2C12.1 2 15 4.9 15 8.5S12.1 15 8.5 15 2 12.1 2 8.5 4.9 2 8.5 2zm0-2C3.8 0 0 3.8 0 8.5S3.8 17 8.5 17 17 13.2 17 8.5 13.2 0 8.5 0zM15 16a1 1 0 1 1 2 0 1 1 0 1 1-2 0'%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
    }
  }

}

// sticky header
.sticky {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}
