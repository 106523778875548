.collection-preview {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  
    .title {
      font-size: 28px;
      margin-bottom: 25px;
      text-align: center;
    }

    .title:hover {
      cursor: pointer;
    }
  
    .preview {
      display: flex;
      justify-content: space-between;
    }
}