.mainpage-image {
  background-size: cover;
  grid-column: 2/4;
  border: 1px solid black;
  position: relative;
  overflow: hidden;

    .carousel {
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      background-size: cover;

      .image-temporary{
        height: 100%;
        width: 100%;
        align-self: flex-start;
      } 

      .carousel__photo {
        opacity: 0;
        position: absolute;
        width: 100px;
        margin: auto;
        background-position: center;
        padding: 13rem 17rem;
        z-index: 100;
        transition: transform .5s, opacity .5s, z-index .5s;
        
      }
    
      /* Display the initial item and bring it to the front using 'z-index'. 
        These styles also apply to the 'active' item. */
      .carousel__photo.initial,
      .carousel__photo.active {
        opacity: 1;
        position: relative;
        z-index: 900;
      }
    
      /* Set 'z-index' to sit behind our '.active' item. */
      .carousel__photo.prev,
      .carousel__photo.next {
        z-index: 800;
      }
    
      /* Translate previous item to the left */
      .carousel__photo.prev {
        transform: translateX(-100%);
      }
    
      /* Translate next item to the right */
      .carousel__photo.next {
        transform: translateX(100%);
      }

    }
  

  

  .carousel-btn {
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.103);
    border: none;
    height: 60px;
    width: 40px;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    .carousel-btn-icon {
      height: 24px;
      width: 24px;
      stroke: #000000;
    }

    &:hover {
      background-color: rgb(230, 230, 230);
    }

    
  }

  .carousel-btn-left {
    /* in relation to PARENT element */
    left:0;
    /* in relation to element ITSELF */
    transform: translate(0,-50%);
    // box-shadow:0 12px 24px rgba(0, 0, 0, 0.30);
  }

  .carousel-btn-right {
    right:0;
    transform: translate(0,-50%);
    // box-shadow:0 12px 24px rgba(0, 0, 0, 0.30); 
  }

  .dots {
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -10px);
    display: flex;
    gap: 5px;

    .dot {
      height: 5px;
      width: 24px;
      background-color: white;
      // border: 2px solid #087f5b;
      border: none;
      cursor: pointer;
    }

    .dot--fill {
      background-color: #686868;
    }

  }

}
