.custom-button {
  width: 165px;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0%;
  font-size: 15px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 10px; 
  
  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }

  &.google-sign-in {
    width: auto;
    padding: 0 13px 0px 13px;; 
    background-color: #4285f4;
    color: white;
    border-radius: 10px; 

    &:hover {
      background-color: #3170d6;
      border: none;
    }
  }

  &.inverted {
    background-color: white;
    color: black;
    border: 1px solid black;
 
    &:hover {
      background-color: black;
      color: white;
      border: none;
    }
  }


}