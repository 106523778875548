.collection-item {
  position:relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 200px;
  justify-content: flex-start;
  overflow: hidden;

  // temporary
  color: #6d6d6d;
  font-weight: 600;
  font-size: 14px;

  .collection-item-banner {
    position: absolute;
    top: 6%;
    right: -10%;
    font-size: 8px;
    font-weight: 700;
    padding: 2px 18px;
    background-color: orangered;
    color: white;
    transform: rotate(45deg);
  }
  .collection-item-banner-none {
    visibility: hidden;
    position: absolute;
  }

  .onsale-banner {
    position: absolute;
    height: 48px;
    width: 68px;
    top: 0;
    left: -12px;
  }

  .onsale-banner-none {
    visibility: hidden;
    position: absolute;
  }


  .image {
    height:300px; 
    width:200px;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-size: cover;
    background-position: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 10px;
  }

  .button {
    color: white;
    border-radius: 10px; 
    background-color: #4285f4;
    padding: 8px 20px;
    text-align: center;
    font-weight: 400;
  }
  
  
  .button:hover {
    cursor: pointer;
    background-color: #2476fa;
    box-shadow: 0 6px 3px rgba(41, 41, 41, 0.212);
    transform: translateY(-1px);
  }
  
  .button:active {
    transform: translateY(0.5px);
    box-shadow: 0 2px 3px rgba(33, 33, 33, 0.473);
  }

  .content-title {
    display: flex;
    padding: 0 5px;
  }
  .content-price {
    display: flex;
    padding: 0 5px;
    margin-top: 4px;

    .collection-item-shipping {
      margin-top: 5px;
      margin-left: 10px;
    }
  }
  
  .content-price:last-child {
    font-size: smaller;
    padding: 0 5px;
  }

  .content-price-bold {
    font-size: 18px;
    padding: 0 6px 0 0;
  }
  
  .content-price-percent {
    display: flex;
    align-self: flex-end;
    gap: 6px;
    padding-bottom: 1px;
  }
}

