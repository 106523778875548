* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  src: local('Rubik'), url('./assets/fonts/Rubik-Light.woff2') format('woff2');
  }

body {
  margin: 0;
  font-family: 'Rubik',sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Scroll Bar */
::-webkit-scrollbar {
  height: .5rem;       
  width: .5rem;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 .5rem rgba(128, 128, 128, 0.39); 
  border-radius: 1rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #343a40; 
  border-radius: 1rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #676b70; 
}
