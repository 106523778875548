.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;

  .image-container {
    width: 23%;
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .name,
  .quantity,
  .price {
    width: 23%;
  }

  .quantity {
    display: flex;

    .arrow {
      cursor: pointer;
      padding: 0 8px ;
      background-color: #e2e5e7;
    }

    .arrow:hover {
      background-color: #b6b6b6;
    }

    .value {
      margin: 2px 10px;
    }
  }

  .remove-button {
    padding: 0 8px;
    cursor: pointer;
    background-color: #e2e5e7;
  }

  .remove-button:hover {
    background-color: #b6b6b6;
  }
}
